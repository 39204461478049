export const ROUTES = {
  HOME: "/",
  CONTACTS: "/contacts",
  REGISTER: "/register",
  STEPS: "/steps",
};

export const IMAGE_URL = `${process.env.REACT_APP_API_URL}/files`;
export const ICON_URL = `${process.env.REACT_APP_API_URL}/images/icons`;
export const VIDEO_URL = `${process.env.REACT_APP_API_URL}/video`;
