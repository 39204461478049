import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import AppRoutes from "./routes/appRoutes";
import configureStore from "./store/store";
import { Loader, Notifications } from "./components";
import "./App.scss";

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <div className="App">
      <PersistGate loading={<Loader />} persistor={persistStore(store)}>
        <BrowserRouter>
          <AppRoutes />

          <Notifications />
        </BrowserRouter>
      </PersistGate>
    </div>
  </Provider>
);

export default App;
