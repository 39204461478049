import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ui from "./ui";

const uiPersistConfig = { key: "ui", storage, blacklist: ["notification"] };

const rootReducer = combineReducers({
  ui: persistReducer(uiPersistConfig, ui),
});

export default rootReducer;
