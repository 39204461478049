import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uiAction, uiOperation } from "../../store/ui";
import "./styles.scss";

const Notifications = ({ notificationHide, notification }) => {
  const autoHide = (duration = 3000) => {
    setTimeout(() => {
      notificationHide();
    }, duration);
  };

  if (notification) {
    return (
      <div className={`Notifications ${notification.variant}`}>
        <div className="Notifications_content">
          <button
            className="Notifications_content__close"
            onClick={notificationHide}
          >
            +
          </button>
          <p>{notification.message}</p>
        </div>
        {autoHide()}
      </div>
    );
  }

  return <></>;
};

Notifications.defaultProps = {
  notification: null,
  variant: "error",
  message: "",
};
Notifications.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string,
  notificationHide: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.string,
  }),
};

const mapStateToProps = ({ ui: { notification } }) => ({ notification });

const mapDispatchToProps = {
  notify: uiOperation.notify,
  notificationHide: uiAction.notificationHide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
