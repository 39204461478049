import React from "react";
import "./style.scss";
import CSV from "../../assets/img/CSV.png";
import Example from "../../assets/img/Example.png";

const InfoCSV = () => {
  return (
    <div className="info_csv">
      <h5 className="info_csv__title">CSV file format</h5>
      <ul className="info_csv__points">
        <li>
          The variables of the column header need to match the variables of step
          1 in the same order.
        </li>
        <li> Start with a simple spreadsheet and save as .CSV, then upload </li>
      </ul>
      <div className="info_csv__img_wrapper">
        <img src={Example} alt="img" />
        <img src={CSV} alt="img" />
      </div>
    </div>
  );
};

InfoCSV.defaultProps = {};
InfoCSV.propTypes = {};

export default InfoCSV;
