import React from "react";
import PropTypes from "prop-types";
import PublishPage from "../../assets/svg/publishPage.svg";
import "./style.scss";

const PublishConfirmation = ({ publishPage, previewPage }) => {
  return (
    <div className="publish_confirmation">
      <div className="publish_confirmation__title_wrapper">
        <img src={PublishPage} alt="img" />
        <h3 className="title">Publish pages</h3>
      </div>
      <div className="publish_confirmation__container">
        <div className="publish_confirmation__container__buttons_wrapper">
          <button className="btn btn_publish" onClick={publishPage}>
            Publish your pages now
          </button>
          <button className="btn_review btn-outline btn " onClick={previewPage}>
            Preview a page
          </button>
        </div>
        <div className="publish_confirmation__container__info">
          <p>
            Your pages will be publish under video.parruu.com/for-
            {"{{Company}}"} If you haven’t set a {"{{ Company }}"} variable, the
            pages will be published with a numeric id starting with1
          </p>
        </div>
      </div>
    </div>
  );
};

PublishConfirmation.defaultProps = {};
PublishConfirmation.propTypes = {
  publishPage: PropTypes.func.isRequired,
  previewPage: PropTypes.func.isRequired,
};

export default PublishConfirmation;
