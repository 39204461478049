import React from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Footer, Header, Steps } from "../../components";
import { ROUTES } from "../../constants/routes";
import OneStartImg from "../../assets/img/start-page-img.png";
import MultipleStartImg from "../../assets/img/start-page-imgs.png";
import "./styles.scss";

const StartPage = () => {
  return (
    <>
      <Header />

      <main className="StartPage">
        <h2 className="StartPage_title">
          Personalize your sales video page at scale
        </h2>
        <div className="StartPage_example">
          <div className="grid">
            <p>From this</p>
            <div className="grid_cols">
              <span>to this</span>
              <span>and this</span>
              <span>and this</span>
              <span>etc.</span>
            </div>

            <img src={OneStartImg} alt="example" />
            <img src={MultipleStartImg} alt="multi-example" />
          </div>
        </div>

        <p className="StartPage_blue text-blue text-center m-b-75 m-t-40">
          in 3 simple steps!
        </p>

        <div className="StartPage_whiteWrap">
          <Steps />
          <p className="text-blue text-center m-b-25">
            Welcome to the parruu video sales page personalizer
          </p>
          <p className="text-black text-center m-b-25">
            Personalize up to 5 variables with 20 entries!
          </p>
          <Link to={ROUTES.STEPS} className="btn m-b-100">
            Click to start creating your page*
          </Link>
          <p className="StartPage_whiteWrap__bottom m-t-50">
            *This alpha testing version is free of charge. Please note, that by
            using the parruu page personalize, you agree that your data will be
            processed and saved to create your pages. Your data will only be
            used for this purpose. Neither 3rd parties have access, nor will
            your data be copied or sold. We delete all your data after 3 months.
          </p>
        </div>
      </main>

      <Footer />
    </>
  );
};

StartPage.defaultProps = {};
StartPage.propTypes = {};

export default StartPage;
