import { notificationShow } from "./action";
import types from "./types";

const notify = ({ message, variant }) => (dispatch) => {
  dispatch(notificationShow({ message, variant }));
};

const uploadFile = (data) => ({
  type: types.SEND_FILE,
  payload: {
    request: {
      url: "/api/files",
      method: "POST",
      data,
    },
  },
});

const uploadFileThunk = (data) => async (dispatch) => {
  const response = await dispatch(uploadFile(data));
  console.log(response);
};

export { notify, uploadFileThunk };
