import React, { Suspense } from "react";
import { useLocation } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import { ContactsPage, Page404, StartPage, StepsPage } from "../pages";
import { Loader } from "../components";
import { ROUTES } from "../constants/routes";

const AppRoutes = () => {
  const location = useLocation();
  const fixUrl = () =>
    location.pathname.slice(-1) !== "/" ? (
      <Redirect to={`${location.pathname}/`} />
    ) : (
      <></>
    );

  return (
    <Suspense fallback={Loader}>
      <Switch>
        <Route exact strict path="/:url" render={fixUrl} />
        <Route exact component={StartPage} path={ROUTES.HOME} />
        <Route exact component={ContactsPage} path={ROUTES.CONTACTS} />
        <Route exact component={StepsPage} path={ROUTES.STEPS} />
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
