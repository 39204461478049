import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Input = ({ type, name, value, onChange, placeholder, maxLength }) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeHandler = (e) => {
    if (e.target.value.length <= maxLength) {
      if (onChange && typeof onChange === "function") {
        onChange(e);
      }
      setInputValue(e.target.value);
    }
  };

  return (
    <div className="Input">
      <span className="Input_counter">
        {inputValue.length || 0}/{maxLength}
      </span>
      <input
        type={type}
        name={name}
        value={inputValue}
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  name: "",
  value: "",
  placeholder: "",
  onChange: null,
  maxLength: 100,
};
Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
