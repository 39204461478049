import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const SelectLogo = ({ onSelect, className, name }) => {
  const [img, setImg] = useState(null);

  const getFileHandler = (event) => {
    if (onSelect && typeof onSelect === "function") {
      onSelect(event);
    }

    if (event.target.files.length) {
      setImg(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div className={`SelectLogo ${className}`}>
      <div className="SelectLogo_preview">
        {img && <img src={img} alt="logo" />}
      </div>
      <div className="SelectLogo_btn">
        <button className="btn btn-white">
          <input
            type="file"
            name={name}
            accept="image/jpg, image/png"
            onChange={getFileHandler}
          />
          <span>Select logo</span>
        </button>
        <span className="p-t-5 text-center">only JPG or PNG</span>
      </div>
    </div>
  );
};

SelectLogo.defaultProps = {
  onSelect: null,
  className: null,
  name: null,
};
SelectLogo.propTypes = {
  onSelect: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
};

export default SelectLogo;
