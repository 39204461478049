import PropTypes from "prop-types";
import React from "react";
import IconSCV from "../../assets/svg/iconSCV.svg";
import "./style.scss";

const CSVadded = ({ onClickHandler }) => {
  return (
    <div className="scv_uploaded">
      <img src={IconSCV} alt="icon" />
      <button className="delete_scv" onClick={onClickHandler}>
        Delete
      </button>
    </div>
  );
};

CSVadded.defaultProps = {};
CSVadded.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
};

export default CSVadded;
