import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const UploadCSV = ({ getFileHandler }) => {
  return (
    <div className="upload_csv">
      <h4 className="upload_csv__title">Upload CSV with variables</h4>
      <input type="file" onChange={getFileHandler} id="files" />
      <label htmlFor="files" className="upload_csv__input">
        Click to upload CSV file
      </label>
      <h5 className="upload_csv__description">
        only .csv format, max. 5 columns (5 variables), max. 30 lines
      </h5>
    </div>
  );
};

UploadCSV.defaultProps = {};
UploadCSV.propTypes = {
  getFileHandler: PropTypes.func.isRequired,
};

export default UploadCSV;
