import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const ModalConfirmation = ({
  closeModal,
  confirmCallback,
  cancelCallback,
  isOpen = false,
}) => {
  return (
    <div
      className="modal_confirmation"
      style={{ display: `${isOpen ? "flex" : "none"}` }}
    >
      <div className="modal_confirmation__body">
        <button
          onClick={closeModal}
          className="modal_confirmation__body__close"
        >
          Close
        </button>
        <h3 className="modal_confirmation__body__title">
          Are you sure you want to delete your pages? You will loose all data
          and need to start again.
        </h3>
        <div className="modal_confirmation__body__buttons_wrapper">
          <button className="btn btn_confirm " onClick={confirmCallback}>
            Yes
          </button>
          <button
            className="btn btn-outline-blue btn_cancel"
            onClick={cancelCallback}
          >
            Not now
          </button>
        </div>
      </div>
    </div>
  );
};

ModalConfirmation.defaultProps = {};
ModalConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ModalConfirmation;
