import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router";
import PublishConfirmation from "../../components/publishConfirmation";
import PublishSuccess from "../../components/publishSuccess";

const PublishPages = () => {
  const history = useHistory();
  const [publish, setPublish] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const publishPage = () => setPublish((prevState) => !prevState);
  const cancelCallback = () => setIsOpen(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const deletePage = () => {
    alert("Delete pages now");
    setPublish((prevState) => !prevState);
  };

  const confirmCallback = () => {
    alert("modalConfirm");
    setIsOpen(false);
  };

  const downloadCSV = () => alert("Download CSV with URLs");
  const checkPage = () => alert("Check a page");
  const previewPage = () => history.push("./preview-page");

  return (
    <div className="publish_page">
      {publish ? (
        <PublishSuccess
          closeModal={closeModal}
          confirmCallback={confirmCallback}
          cancelCallback={cancelCallback}
          isOpen={isOpen}
          downloadCSV={downloadCSV}
          checkPage={checkPage}
          deletePage={deletePage}
          openModal={openModal}
        />
      ) : (
        <PublishConfirmation
          previewPage={previewPage}
          publishPage={publishPage}
        />
      )}
    </div>
  );
};

PublishPages.defaultProps = {};
PublishPages.propTypes = {};

export default PublishPages;
