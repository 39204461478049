import React from "react";
import { Footer, Header } from "../../components";
// import PropTypes from "prop-types";

const ContactsPage = () => (
  <>
    <Header withBackRoute />
    <main>
      <h2>Contacts</h2>
      <ul>
        <li>tel: +38067945456</li>
        <li>
          email: <a href="mainto:test@email.com">test@email.com</a>
        </li>
      </ul>
    </main>
    <Footer />
  </>
);

ContactsPage.defaultProps = {};
ContactsPage.propTypes = {};

export default ContactsPage;
