import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Loader from "../loader";
import "./style.scss";

const FileUpload = ({
  name,
  accept,
  onChange,
  onDelete,
  className,
  type,
  btnText,
  withPreview,
  infoText,
  loading,
}) => {
  const inputEl = useRef(null);
  const [file, setFile] = useState(null);

  const changeHandler = (event) => {
    if (onChange && typeof onChange === "function") {
      onChange(event);
    }

    setFile(event.target.files[0]);
  };

  const deleteHandler = () => {
    if (onDelete && typeof onDelete === "function") {
      onDelete({ file, element: inputEl.current });
    }

    setFile(null);
    inputEl.current.value = null;

    if (onChange && typeof onChange === "function") {
      onChange({ target: inputEl.current });
    }
  };

  if (loading) {
    return (
      <div>
        <Loader secondary />
        <p className="m-t-10 text-center">Please wait...</p>
      </div>
    );
  }

  return (
    <div className={`FileUpload ${className}`}>
      {withPreview && (
        <div className="FileUpload_preview">
          {file && <img src={URL.createObjectURL(file)} alt="preview" />}
        </div>
      )}

      <div className="FileUpload_wrap">
        {file && file.name && (
          <div className="btn m-b-10 btn-white btn-notHovered">
            <span>{file.name}</span>
            <button
              aria-label="delete"
              onClick={deleteHandler}
              className="FileUpload_delete"
            />
          </div>
        )}

        <label
          className={`btn m-b-10 btn-white ${file && file.name && "d-none"}`}
        >
          <input
            type="file"
            name={name}
            accept={
              type === "image" ? "image/png, image/jpeg, image/gif" : accept
            }
            onChange={changeHandler}
            ref={inputEl}
          />
          <span>{btnText || `Click to upload ${type}`}</span>
        </label>

        {infoText && <span className="text-center">{infoText}</span>}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  name: "",
  accept: "video/mp4",
  className: "",
  btnText: "",
  infoText: null,
  type: "image",
  onChange: null,
  onDelete: null,
  withPreview: false,
  loading: false,
};
FileUpload.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  btnText: PropTypes.string,
  infoText: PropTypes.string,
  type: PropTypes.oneOf(["video", "image"]),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  withPreview: PropTypes.bool,
  loading: PropTypes.bool,
};

export default FileUpload;
