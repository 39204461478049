import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { ROUTES } from "../../constants/routes";
import "./styles.scss";

const Header = ({ withBackRoute, history: { goBack } }) => (
  <header className="Header">
    <Link to={ROUTES.HOME} className="Header_top__logo">
      <img src={Logo} alt="Logo" />
    </Link>
    <h2 className="Header_title">Alpha Testing Version</h2>
    {!withBackRoute ? (
      <Link to={ROUTES.CONTACTS} className="routeBtn btn">
        Contacts
      </Link>
    ) : (
      <button onClick={goBack} className="routeBtn btn">
        Back
      </button>
    )}
  </header>
);

Header.defaultProps = {
  withBackRoute: false,
};
Header.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  withBackRoute: PropTypes.bool,
};

export default withRouter(Header);
