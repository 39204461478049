import React from "react";
import "./styles.scss";

const Footer = () => (
  <footer className="Footer">
    <div className="Footer_left">
      <a href="/">T & C</a>
      <a href="/">Contact & Imprint</a>
    </div>
    <div className="Footer_center">
      <p>parruu AG, Saumstrasse 11, 8003 Zürich - contact@parruu.com </p>
    </div>
    <div className="Footer_right">
      <p>© 2019-2020 by parruu AG</p>
    </div>
  </footer>
);

export default Footer;
