import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FileUpload,
  Input,
  Loader,
  TextArea,
  VariablesSelect,
} from "../../components";
import TitleIcon from "../../assets/icons/createpage.svg";
import "./style.scss";

const CreatePage = ({ next }) => {
  const [formValues, setFormValues] = useState({
    logo: null,
    title: `Example:

Increase your conversion with personalization, {{Firstname}}!`,
    titleVariable: null,
    body: `Example:

You are using sales videos on landing pages at {{Company}} in {{Location}}.

The results are great but the conversion rate can’t be high enough, right?

Personalize your landing page with already existing data on your leads in a few clicks will help skyrocket your CR!

We’ve helped clients like {{Client1}} or {{Client2}} with our no-bullshit page builder for sales reps who are busy selling and not with complicated tools.`,
    bodyVariable: null,
    video: null,
    actionTitle: "",
    actionLink: "",
  });
  const [loading, setLoading] = useState(false);

  const setValueHandler = ({ target: { name, value, files } }) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: files && files.length ? files : value,
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      console.log({ formValues, form: e.target });
      next(2);
    }, 2000); // TODO for testing
  };

  const deleteFileHandler = ({ file, element }) => {
    console.log({ file, element });
  };

  if (loading) {
    return (
      <div className="CreatePage loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="CreatePage">
      <h5 className="stepTitle text-center m-b-30">
        <img src={TitleIcon} alt="create-page" />
        <span>Create page</span>
      </h5>

      <button className="btn btn-outline CreatePage_preview">
        Preview page
      </button>

      <form onSubmit={submitHandler}>
        <div className="row m-b-20">
          <FileUpload
            type="image"
            name="logo"
            className="row_center"
            onChange={setValueHandler}
            onDelete={deleteFileHandler}
            btnText="Select logo"
            accept="image/jpg, image/png"
            infoText="only JPG or PNG"
            withPreview
          />
        </div>

        <div className="row m-b-30">
          <div className="border">
            <p className="row_title text-center m-b-20">Title section</p>
            <TextArea
              name="title"
              className="m-b-15"
              onChange={setValueHandler}
              value={formValues.title}
            />

            <VariablesSelect onChange={setValueHandler} name="titleVariable" />
          </div>
          <div className="text-red">
            <p>Script format</p>
            <p>
              • The variables for personalization need to match the column
              header of the CSV file of step 2.
            </p>
            <p>Example script</p>
            <p>
              Increase your conversion with personalization,
              <span>{"{{Firstname}}"}!</span>
            </p>
          </div>
        </div>

        <div className="row m-b-30">
          <div className="border border_dashed">
            <p className="row_title text-center m-b-20">Add video</p>
            <FileUpload
              type="video"
              name="video"
              className="row_center"
              onChange={setValueHandler}
              onDelete={deleteFileHandler}
            />
          </div>
        </div>

        <div className="row m-b-30">
          <div className="border">
            <p className="row_title text-center m-b-20">Body section</p>
            <TextArea
              name="body"
              rows="12"
              className="m-b-15"
              onChange={setValueHandler}
              value={formValues.body}
              placeholder="Enter the body text"
            />

            <VariablesSelect onChange={setValueHandler} name="bodyVariable" />
          </div>
          <div className="text-red">
            <p>Script format</p>
            <p>
              • The variables for personalization need to match the column
              header of the CSV file of step 2.
            </p>
            <p>Example script</p>
            <p>
              You are using sales videos on landing pages at {"{{Company}}"} in{" "}
              {"{{Location}}"}.
            </p>
            <p>
              The results are great but the conversion rate can’t be high
              enough, right?
            </p>
            <p>
              Personalize your landing page with already existing data on your
              leads in a few clicks will help skyrocket your CR!
            </p>
            <p>
              We’ve helped clients like {"{{Client1}}"} or {"{{Client2}}"} with
              our no-bullshit page builder for sales reps who are busy selling
              and not with complicated tools.
            </p>
          </div>
        </div>

        <div className="row m-b-30">
          <div className="border">
            <p className="row_title text-center m-b-20">
              Call to action button
            </p>
            <Input
              maxLength={10}
              name="actionTitle"
              onChange={setValueHandler}
              placeholder="Add title of your call to action button"
            />
            <Input
              maxLength={10}
              name="actionLink"
              onChange={setValueHandler}
              placeholder="Add link"
            />
          </div>
        </div>

        <div className="row row_right">
          <button className="btn btn-blue" type="submit" disabled={loading}>
            Next to upload variables
          </button>
        </div>
      </form>
    </div>
  );
};

CreatePage.defaultProps = {};
CreatePage.propTypes = {
  next: PropTypes.func.isRequired,
};

export default CreatePage;
