import React, { useState } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import UploadCSV from "../../components/uploadCSV";
import InfoCSV from "../../components/InfoCSV";
import CSVadded from "../../components/CSVadded";
import { uploadFileThunk } from "../../store/ui/operation";

const AddVariables = ({ next }) => {
  const nextHandler = () => next(3);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const getFileHandler = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("attr", "file");
    dispatch(uploadFileThunk({ name: "test" }));
    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const deleteFile = () => setFile("");

  return (
    <div className="add_variables_step">
      <h3 className="text-center m-b-30 title">
        <span className="f">{"{f}"}</span>Add variables
      </h3>
      <div className="container">
        {file ? (
          <CSVadded onClickHandler={deleteFile} />
        ) : (
          <UploadCSV getFileHandler={getFileHandler} />
        )}
        <InfoCSV />
      </div>
      {file && (
        <button className="btn" onClick={nextHandler}>
          Next to publish pages
        </button>
      )}
    </div>
  );
};

AddVariables.defaultProps = {};
AddVariables.propTypes = {
  next: PropTypes.func.isRequired,
};

export default AddVariables;
