import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const VariablesSelect = ({ name, onChange, className }) => {
  const [variables] = useState([
    { name: "FirstName", value: 1 },
    { name: "Company", value: 2 },
    { name: "Location", value: 3 },
    { name: "Custom (must fit CSV)", value: 4 },
  ]);

  return (
    <select
      name={name}
      defaultValue="DEFAULT"
      onChange={onChange}
      className={`VariablesSelect ${className}`}
    >
      <option disabled value="DEFAULT">
        Insert variable
      </option>
      {variables.map((variable) => (
        <option key={`startVariable${variable.value}`} value={variable.value}>
          {variable.name}
        </option>
      ))}
    </select>
  );
};

VariablesSelect.defaultProps = {
  name: "",
  onChange: null,
  className: "",
};
VariablesSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default VariablesSelect;
