import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Steps = ({ steps, activeStep }) => (
  <ul className="Steps">
    {steps.map((step, i) => (
      <li
        className={activeStep && activeStep === i + 1 ? "active" : ""}
        key={`step-${step.trim()}`}
      >{`${i + 1}. ${step}`}</li>
    ))}
  </ul>
);

Steps.defaultProps = {
  steps: ["Create page", "Add variables", "Publish pages"],
  activeStep: null,
};
Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
};

export default Steps;
