import React, { useState } from "react";
import { Footer, Header, Steps } from "../../components";
import { CreatePage, AddVariables, PublishPages } from "../../containers";

const StepsPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const nextStepHandler = () => {
    const stepNum = activeStep >= 3 ? 1 : activeStep + 1;
    setActiveStep(stepNum);
  };

  const renderStep = () => {
    let activeStepComponent;

    switch (activeStep) {
      case 2:
        activeStepComponent = <AddVariables next={nextStepHandler} />;
        break;
      case 3:
        activeStepComponent = <PublishPages next={nextStepHandler} />;
        break;
      default:
        activeStepComponent = <CreatePage next={nextStepHandler} />;
    }

    return activeStepComponent;
  };

  return (
    <>
      <Header />
      <main>
        <h1 className="Title">Personalize your sales video page at scale</h1>
        <div className="main_wrap">
          <Steps activeStep={activeStep} />

          <div className="main_steps">{renderStep()}</div>
        </div>
      </main>
      <Footer />
    </>
  );
};

StepsPage.defaultProps = {};
StepsPage.propTypes = {};

export default StepsPage;
