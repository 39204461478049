import React from "react";
import PropTypes from "prop-types";
import PublishSuccessSVG from "../../assets/svg/publishSuccess.svg";
import "./style.scss";
import ModalConfirmation from "../modal";

const PublishSuccess = ({
  checkPage,
  downloadCSV,
  cancelCallback,
  closeModal,
  confirmCallback,
  isOpen,
  openModal,
}) => {
  return (
    <div className="publish_success">
      <ModalConfirmation
        cancelCallback={cancelCallback}
        closeModal={closeModal}
        confirmCallback={confirmCallback}
        isOpen={isOpen}
      />
      <div className="publish_success__title_wrapper">
        <img src={PublishSuccessSVG} alt="img" />
        <h3 className="title">Your pages are published!</h3>
      </div>
      <div className="publish_success__container">
        <div className="publish_success__container__buttons_wrapper">
          <button className="btn btn_publish" onClick={downloadCSV}>
            Download CSV with URLs
          </button>
          <button className="btn_review btn-outline btn " onClick={checkPage}>
            Check a page
          </button>
        </div>
        <div className="publish_success__container__info">
          <p>
            You can download a CSV to make add the published pages to your
            communication.
          </p>
        </div>
      </div>
      <div className="publish_success__container__delete">
        <div className="publish_success__container__buttons_wrapper">
          <button className="btn_delete btn-danger btn " onClick={openModal}>
            Delete pages now
          </button>
        </div>
        <div className="publish_success__container__info">
          <p>
            Your pages will be deleted after 2 weeks automatically in this alpha
            testing version.
          </p>
        </div>
      </div>
    </div>
  );
};

PublishSuccess.defaultProps = {};
PublishSuccess.propTypes = {
  checkPage: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  downloadCSV: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default PublishSuccess;
