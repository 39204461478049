import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const TextArea = ({
  name,
  className,
  onChange,
  value,
  rows,
  placeholder,
  maxLength,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(value);

  const onChangeValue = (event) => {
    if (event.target.value.length <= maxLength) {
      if (onChange ?? typeof onChange === "function") {
        onChange(event);
      }

      setTextAreaValue(event.target.value);
    }
  };

  return (
    <label className={`TextArea ${className}`}>
      <span className="counter">
        {textAreaValue.length}/{maxLength}
      </span>
      <textarea
        name={name}
        rows={rows}
        onChange={onChangeValue}
        value={textAreaValue}
        placeholder={placeholder}
      />
    </label>
  );
};

TextArea.defaultProps = {
  name: "",
  className: "",
  onChange: null,
  value: "",
  rows: 4,
  maxLength: 100,
  placeholder: "Enter the text",
};
TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
};

export default TextArea;
